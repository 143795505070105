import React, { createContext, useState } from 'react'
const CursorContext = createContext({ snappedElement: null })

const CursorProvider = function ({ children }) {
  const [cursor, setCursor] = useState({ snappedElement: null })

  return <CursorContext.Provider value={{ cursor, setCursor }}>{children}</CursorContext.Provider>
}

export default CursorContext
export { CursorProvider }
