import React from 'react'
import { AnimatePresence } from 'framer-motion'
import { ThemeProvider } from './src/context/ThemeContext'
import { CursorProvider } from './src/context/CursorContext'

export const wrapRootElement = ({ element }) => (
  <ThemeProvider>
    <CursorProvider>{element}</CursorProvider>
  </ThemeProvider>
)

export const wrapPageElement = ({ element }) => <AnimatePresence>{element}</AnimatePresence>
