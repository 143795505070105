import React, { createContext } from 'react'
import useLocalStorage from '../hooks/useLocalStorage'
const ThemeContext = createContext('default')

const ThemeProvider = function ({ children }) {
  const [theme, setTheme] = useLocalStorage('ooe_theme', 'default')
  return <ThemeContext.Provider value={{ theme, setTheme }}>{children}</ThemeContext.Provider>
}

export default ThemeContext
export { ThemeProvider }
